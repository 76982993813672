import React from "react";
import PropTypes from "prop-types";

const Partner = ({ label, logo, url, className }) => (
  <li className={`bp-center ${className}`}>
    <a href={url} target="_blank" rel="noreferrer noopener nofollow" aria-label={label}>
      <img src={logo} alt="" />
    </a>
  </li>
);

Partner.propTypes = {
  label: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string
};

Partner.defaultProps = {
  className: ""
};

export default Partner;
