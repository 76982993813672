import VRKLogo from "../src/assets/partner-logos/vrk.jpg";
import PolicijaLogo from "../src/assets/partner-logos/policija.jpg";
import BiteLogo from "../src/assets/partner-logos/bite.png";
import TwoGoLogo from "../src/assets/partner-logos/2go.png";
import OwexxLogo from "../src/assets/partner-logos/owexx.jpg";
import TCGLogo from "../src/assets/partner-logos/tcg.png";
import MailerliteLogo from "../src/assets/partner-logos/mailerlite.png";
import RYOLogo from "../src/assets/partner-logos/ryo.png";
import MinijaLogo from "../src/assets/partner-logos/minija.png";
import AvilysLogo from "../src/assets/partner-logos/avilys.jpg";
import LIJOTLogo from "../src/assets/partner-logos/lijot.jpg";
import LMSLogo from "../src/assets/partner-logos/lms.jpg";
import ZinauKaRenkuLogo from "../src/assets/partner-logos/zinau-ka-renku.jpg";
import ManNeDzinLogo from "../src/assets/partner-logos/man-ne-dzin.jpg";
import ManoBalsasLogo from "../src/assets/partner-logos/mano-balsas.jpg";
import ManoSeimasLogo from "../src/assets/partner-logos/mano-seimas.jpg";
import PVILogo from "../src/assets/partner-logos/pvi.jpg";
import TILogo from "../src/assets/partner-logos/transparency.jpg";

export const PARTNERS = [
  {
    key: "vrk",
    logo: VRKLogo,
    url: "https://www.vrk.lt/"
  },
  {
    key: "policija",
    logo: PolicijaLogo,
    url: "https://policija.lrv.lt/"
  },
  {
    key: "bite",
    logo: BiteLogo,
    url: "https://www.bite.lt/"
  },
  {
    key: "2go",
    logo: TwoGoLogo,
    url: "https://www.2go.lt/"
  },
  {
    key: "owexx",
    logo: OwexxLogo,
    url: "https://www.owexx.com/lt"
  },
  {
    key: "tcg",
    logo: TCGLogo,
    url: "https://www.tcg.lt/"
  },
  {
    key: "mailerlite",
    logo: MailerliteLogo,
    url: "https://www.mailerlite.com/"
  },
  {
    key: "minija",
    logo: MinijaLogo,
    url: "https://www.gargzdukinas.lt/"
  },
  {
    key: "ryo",
    logo: RYOLogo,
    url: "https://ryo.lt/"
  },
  {
    key: "avilys",
    logo: AvilysLogo,
    url: "http://www.nvoavilys.lt/"
  },
  {
    key: "lms",
    logo: LMSLogo,
    url: "https://www.moksleiviai.lt/"
  },
  {
    key: "lijot",
    logo: LIJOTLogo,
    url: "https://lijot.lt/"
  },
  {
    key: "renku",
    logo: ZinauKaRenkuLogo,
    url: "https://www.zinaukarenku.lt/"
  },
  {
    key: "dzin",
    logo: ManNeDzinLogo,
    url: "https://www.facebook.com/mannedzin/"
  },
  {
    key: "balsas",
    logo: ManoBalsasLogo,
    url: "http://www.manobalsas.lt/"
  },
  {
    key: "seimas",
    logo: ManoSeimasLogo,
    url: "http://manoseimas.lt/"
  },
  {
    key: "pvi",
    logo: PVILogo,
    url: "http://www.civitas.lt/"
  },
  {
    key: "ti",
    logo: TILogo,
    url: "https://www.transparency.lt/"
  }
];
